import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Phone,
  Public,
  ReportProblem,
  Room,
  ShareRounded,
} from '@material-ui/icons';
import camelCase from 'lodash.camelcase';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import {
  Carousel,
  DrawerHeader,
  Flag,
  Message,
  PlaceDetailReviews,
  ReviewMenu,
  Tags,
} from '../components';
import { SharePinDialog } from '../dialogs';
import { MapLayout } from '../layouts';
import { addActions, editActions, mapActions } from '../store/actions';
import { Spacing } from '../styles';
import { ADD_PATH, PLACE_DETAIL_PATH, track } from '../utils';
import getQueryStringLanguage from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  h5: {
    marginBottom: theme.spacing(1),
  },
  lineItemRow: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 3, 0, 3),
  },
  lineItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-start',
    width: '100%',
    padding: theme.spacing(0, 1),
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.pinText,
  },
  partnershipContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(0.5, 3, 0, 3),
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.pinText,
  },
  lineItemIcon: {
    marginRight: theme.spacing(1),
    alignSelf: 'flex-start',
    color: theme.palette.text.pinText,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  reportProblemButton: {
    marginRight: '-4px',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: '-4px',
    },
  },
  successMessageContainer: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    color: theme.palette.text.pinText,
  },
  shareIcon: {
    marginLeft: theme.spacing(1),
  },
  ratingIcon: {
    marginRight: theme.spacing(2),
    width: 38,
  },
  divider: {
    background: theme.palette.border.main,
  },
  partnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.base,
    paddingBottom: 0,
    marginBottom: theme.smaller,
  },
  tctLogo: {
    marginLeft: 5,
    width: 36,
    height: 24,
  },
  wascanaCenterLogo: {
    marginLeft: 5,
    width: 57,
    height: 24,
  },
  cityOfSsurrey: {
    marginLeft: 5,
    width: 72,
    height: 24,
  },
  cibcLogo: {
    marginLeft: 5,
    width: 77,
    height: 20,
  },
  becLogo: {
    marginLeft: 5,
    width: 67,
    height: 'auto',
  },
  georginaLogo: {
    marginLeft: 5,
    width: 57,
    height: 'auto',
  },
  bramptonLogo: {
    marginLeft: 5,
    width: 87,
    height: 'auto',
  },
  uhnLogo: {
    marginLeft: 5,
    width: 50,
    height: 'auto',
  },
  fortLauderdaleLogo: {
    width: 130,
    height: 'auto',
  },
  destinationTOLogo: {
    marginLeft: 5,
    width: 77,
    height: 'auto',
  },
  cowbellBrewingLogo: {
    marginLeft: 5,
    marginBottom: 5,
    width: 50,
    height: 'auto',
  },
  metrolinxLogo: {
    marginLeft: 5,
    width: 110,
    marginBottom: -5,
    height: 'auto',
  },
  boathouseWashroomLogo: {
    marginLeft: 5,
    width: 57,
    height: 'auto',
  },
  beaufortCountyLogo: {
    marginLeft: 5,
    width: 36,
    height: 'auto',
  },
  oxfordCountyLogo: {
    marginLeft: 5,
    width: 36,
    height: 'auto',
  },
  lineItemRowElement: {
    display: 'flex',
    alignItems: 'center',
  },
  verificationIcon: {
    marginLeft: Spacing.tinier,
    width: Spacing.large,
    height: Spacing.large,
    verticalAlign: 'middle',
    marginBottom: '1%',
    objectFit: 'contain',
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-evenly',
    width: '100%',
    padding: theme.spacing(2),
  },
  editReview: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  editButton: {
    width: '55px',
    height: '30px',
    marginRight: '3px',
    alignSelf: 'flex-end',
  },
}));

const DetailsView = () => {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth, shallowEqual);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [isSharePinDialogOpen, setIsSharePinDialogOpen] = useState(false);
  const sharedLinkRouteMatch = useRouteMatch({
    path: '/share',
    strict: true,
    sensitive: true,
  });
  const { t } = useTranslation();
  const { params } = useRouteMatch({
    path: '/details/:id/:platform/:success?',
    strict: true,
    sensitive: true,
  });
  const isGoogle = params.platform === 'google';
  const isSuccess = params.success === 'success';
  const byPlace = useSelector((state) => state.reviews.byPlace);
  const selectedPlaceDetails = useSelector(
    (state) => state.map.selectedPlaceDetails
  );
  const editState = useSelector((state) => state.edit, shallowEqual);
  const [reviewText, setReviewText] = useState(editState.text || '');
  const { isProvider } = useSelector((state) => state.auth);
  const isLoading = useSelector((state) => state.map.isLoading);
  const {
    _id,
    tags,
    description,
    descriptionId,
    labels,
    flags,
    address,
    phone,
    placeType,
    url,
    urlDisplay,
    rating,
    name,
    images,
    provider,
    promotedReview,
    servicePlaceId,
    wheelchairAccessibleEntrance,
  } = selectedPlaceDetails;

  useEffect(() => {
    track.screen('DetailsView');
  }, []);

  useEffect(() => {
    // make sure to only call for details once
    if (isGoogle && params.id !== servicePlaceId && !isLoading) {
      dispatch(mapActions.placeDetails({ _id: params.id, isGoogle }));
    } else if (!isGoogle && params.id !== _id && !isLoading) {
      dispatch(mapActions.placeDetails({ _id: params.id }));
    }
  }, [
    _id,
    isLoading,
    isSuccess,
    params.id,
    dispatch,
    location.state,
    isGoogle,
    servicePlaceId,
  ]);

  useEffect(() => {
    const isFromAdd = location.state?.from === ADD_PATH;
    if (isFromAdd) {
      dispatch(mapActions.placeDetails({ _id: params.id }));
    }
  }, [dispatch, location.state, params.id]);

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const { lng = 'en' } = queryParams;

    if (sharedLinkRouteMatch !== null && 'pinId' in queryParams) {
      history.push(`/details/${queryParams.pinId}/local?lng=${lng}`);
      track.analytics({
        category: 'shared_pin_engagement',
        data: {
          pinId: queryParams.pinId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedLinkRouteMatch]);

  const onOpenShareDialog = () => {
    setIsSharePinDialogOpen(true);
  };

  const onCloseSharePinDialog = () => {
    setIsSharePinDialogOpen(false);
  };

  const onClickAddReview = () => {
    history.push('/add');
    // copy place details to add
    dispatch(editActions.reset());
    dispatch(
      addActions.update({
        ...selectedPlaceDetails,
        tags: [],
        description: '',
        text: '',
        images: [],
        eventId: authState.eventId,
      })
    );
  };

  const onBackToSearch = () => {
    const lngQueryParam = getQueryStringLanguage();

    history.push(`/${lngQueryParam}`, { from: PLACE_DETAIL_PATH });
  };

  const handlePhoneClick = () => {
    track.analytics({
      category: 'details_phone_clicked',
      data: {
        phone,
      },
    });
  };

  const handleUrlClick = () => {
    track.analytics({
      category: 'details_url_clicked',
      data: {
        url,
      },
    });
  };

  const key = rating || t('messages.plus');
  const displayName = rating && t(`ratings.${rating}`);
  const ariaRating = key !== 'Plus' ? `Rated ${displayName}` : 'Not yet rated';
  const reportProblemUri = `mailto:hello@accessnow.ca?subject=Report A Problem&body=${encodeURIComponent(
    `\n\n--- Please don't remove below this line ---\n${name}\n${address}\n${_id}`
  )}`;

  // capitalize(description)
  const localCapitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const onEdit = async () => {
    await dispatch(editActions.editReview(editState));
    dispatch(editActions.reset());
  };
  const handleDescriptionChange = (event) => {
    const str = event.target.value;
    setReviewText(str);

    dispatch(
      editActions.update({
        text: str,
      })
    );
  };
  const reviewTextLength = (reviewText && reviewText.length) || 0;
  const reviewTextCounter = `${1000 - reviewTextLength} ${t(
    'messages.charactersRemaining'
  )}`;
  const partnershipInfo = (provider) => {
    let partnershipMesseage = '';
    let partnershipIcon = ``;
    let iconStyle = '';

    if (provider) {
      const providerCamelCase = camelCase(provider);

      switch (providerCamelCase) {
        case 'tct':
          partnershipMesseage = 'messages.partnershipTCT';
          partnershipIcon = `/images/partners/TCT-Logo-Blue_PMS.png`;
          iconStyle = classes.tctLogo;
          break;
        case 'wascanaCentre':
          partnershipMesseage = 'messages.partnershipWascanaCentre';
          partnershipIcon = `/images/partners/Wascana_Centre_Logo_Vert_CMYK_Lauren_Hope.png`;
          iconStyle = classes.wascanaCenterLogo;
          break;
        case 'cityOfSurrey':
          partnershipMesseage = 'messages.partnershipCityOfSurrey';
          partnershipIcon = `/images/partners/city_of_surrey.png`;
          iconStyle = classes.cityOfSsurrey;
          break;
        case 'cibc':
          partnershipMesseage = 'messages.partnershipCIBC';
          partnershipIcon = `/images/partners/cibc-logo.png`;
          iconStyle = classes.cibcLogo;
          break;
        case 'cityOfBrampton':
          partnershipMesseage = 'messages.partnershipBrampton';
          partnershipIcon = `/images/partners/cob-logo.jpg`;
          iconStyle = classes.bramptonLogo;
          break;
        case 'townOfGeorgina':
          partnershipMesseage = 'messages.partnershipGeorgina';
          partnershipIcon = `/images/partners/georgina-logo.png`;
          iconStyle = classes.georginaLogo;
          break;
        case 'bec':
          partnershipMesseage = 'messages.partnershipBEC';
          partnershipIcon = `/images/partners/bec-square.png`;
          iconStyle = classes.becLogo;
          break;
        case 'brockTownship':
          partnershipMesseage = 'messages.partnershipBrockTownship';
          partnershipIcon = `/images/partners/brock-township.png`;
          iconStyle = classes.becLogo;
          break;
        case 'aurora':
          partnershipMesseage = 'messages.partnershipAurora';
          partnershipIcon = `/images/partners/aurora-logo.png`;
          iconStyle = classes.becLogo;
          break;
        case 'richmondHill':
          partnershipMesseage = 'messages.partnershipRichmondHill';
          partnershipIcon = `/images/partners/richmond-hill-logo.png`;
          iconStyle = classes.bramptonLogo;
          break;
        case 'uhn':
          partnershipMesseage = 'messages.partnershipUHN';
          partnershipIcon = `/images/partners/uhn-logo.png`;
          iconStyle = classes.uhnLogo;
          break;
        case 'fortLauderdale':
          partnershipMesseage = 'messages.partnershipFortLauderdale';
          partnershipIcon = `/images/partners/fort-lauderdale-logo.png`;
          iconStyle = classes.fortLauderdaleLogo;
          break;
        case 'destinationTo':
          partnershipMesseage = 'messages.partnershipDestinationTO';
          partnershipIcon = `/images/partners/destinationTO_logo.png`;
          iconStyle = classes.destinationTOLogo;
          break;
        case 'metrolinx':
          partnershipMesseage = 'messages.partnershipMetrolinx';
          partnershipIcon = `/images/partners/Metrolinx-Logo.png`;
          iconStyle = classes.metrolinxLogo;
          break;
        case 'cowbellBrewing':
          partnershipMesseage = 'messages.partnershipCowbellBrewing';
          partnershipIcon = `/images/partners/Cowbell-Logo.png`;
          iconStyle = classes.cowbellBrewingLogo;
          break;
        case 'boathouseWashroom':
          partnershipMesseage = 'messages.partnershipBoathouseWashroom';
          partnershipIcon = `/images/partners/BW-logo.png`;
          iconStyle = classes.boathouseWashroomLogo;
          break;
        case 'beaufortCounty':
          partnershipMesseage = 'messages.partnershipBeaufortCounty';
          partnershipIcon = `/images/partners/beaufortCounty-logo.png`;
          iconStyle = classes.beaufortCountyLogo;
          break;
        case 'oxfordCounty':
          partnershipMesseage = 'messages.partnershipOxfordCounty';
          partnershipIcon = `/images/partners/oxfordCounty-logo.jpg`;
          iconStyle = classes.oxfordCountyLogo;
          break;
        default:
          return null;
      }
      return (
        <>
          {providerCamelCase === 'cibc' ? (
            <Typography
              className={classes.infoText}
              variant="body2"
              style={{ marginBottom: 6, marginTop: 12 }}
            >
              For hours and services please visit our{' '}
              <Link
                classes={{
                  root: classes.lineItem,
                }}
                href={url}
                variant="body2"
                noWrap
                target="_blank"
                rel="noopener"
                onClick={handleUrlClick}
              >
                website
              </Link>
              .
            </Typography>
          ) : null}
          <div className={classes.partnerWrapper}>
            <Typography
              className={classes.infoText}
              variant="body2"
              style={{ marginBottom: 6, marginTop: 12 }}
            >
              In partnership with
            </Typography>
            <img
              alt={t(partnershipMesseage)}
              src={partnershipIcon}
              className={iconStyle}
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const verificationSign = (provider) => {
    if (provider) {
      const verificationAltTest = 'messages.verifiedPlace';
      const verificationIcon = `/images/partners/verifiedIcon.png`;
      const iconStyle = classes.verificationIcon;
      return (
        <>
          <img
            alt={verificationAltTest}
            src={verificationIcon}
            className={iconStyle}
          />
        </>
      );
    }

    return null;
  };

  return (
    <MapLayout>
      <DrawerHeader title={t('messages.details')} onClick={onBackToSearch} />
      {isLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          {!!isSuccess && (
            <div className={classes.lineItemRow}>
              <Message
                id="form-dialog-description"
                variant="success"
                message={t('messages.thankYouSuccessfullyAdded')}
                style={{ flex: 1 }}
              />
            </div>
          )}
          <div className={classes.lineItemRow}>
            <img
              alt={ariaRating}
              src={`/images/roundIcons/${
                !_id && wheelchairAccessibleEntrance
                  ? 'incomplete_accessible_icon'
                  : placeType === 'trail'
                  ? `trail_${key}`
                  : key
              }.svg`}
              className={classes.ratingIcon}
            />
            <div className={classes.lineItemRowElement} style={{ flex: 1 }}>
              <Typography className={classes.infoText} variant="h3">
                {name}
                {verificationSign(provider)}
              </Typography>
            </div>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={onOpenShareDialog}
              className={classes.shareIcon}
            >
              <ShareRounded color="primary" />
            </IconButton>
          </div>
          {!!flags.length && (
            <div className={classes.lineItemRow}>
              {flags.map((flag, key) => {
                return (
                  <Flag verified key={key} {...flag}>
                    {t(`flags.${flag}`) || flag}
                  </Flag>
                );
              })}
            </div>
          )}
          {!!labels.length && (
            <div className={classes.lineItemRow}>
              {labels.map((label, key) => {
                return (
                  <Flag key={key} {...label}>
                    {label.displayName}
                  </Flag>
                );
              })}
            </div>
          )}
          <div className={classes.lineItemRow}>
            <Room fontSize="small" className={classes.lineItemIcon} />
            <Typography className={classes.infoText} variant="body2">
              {address}
            </Typography>
          </div>
          {!!phone && (
            <div className={classes.lineItemRow}>
              <Phone fontSize="small" className={classes.lineItemIcon} />
              <Link
                href={`tel:${phone}`}
                className={classes.lineItem}
                variant="body2"
                noWrap
                onClick={handlePhoneClick}
              >
                {phone}
              </Link>
            </div>
          )}
          {!!url && (
            <div className={classes.lineItemRow}>
              <Public fontSize="small" className={classes.lineItemIcon} />
              <Link
                href={url}
                className={classes.lineItem}
                variant="body2"
                noWrap
                target="_blank"
                rel="noopener"
                onClick={handleUrlClick}
              >
                {urlDisplay}
              </Link>
            </div>
          )}
          {!!tags.length && (
            <div className={classes.lineItemRow}>
              <Tags {...{ tags }} variant="body2" />
            </div>
          )}
          {!!description ? (
            <div className={classes.description}>
              <div className={classes.lineItemColumn}>
                <Typography
                  className={classes.infoText}
                  variant="h5"
                  style={{ marginBottom: 6 }}
                >
                  {t('messages.description')}
                </Typography>
                {editState._id === descriptionId ? (
                  <div className={classes.editReview}>
                    <form
                      noValidate
                      autoComplete="off"
                      className={classes.descriptionForm}
                    >
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        minRows="5"
                        label="Edit Description"
                        variant="outlined"
                        style={{
                          width: '100%',
                        }}
                        helperText={reviewTextCounter}
                        inputProps={{ maxLength: 1000 }}
                        value={editState.text}
                        onChange={handleDescriptionChange}
                      />
                    </form>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.editButton}
                      size="medium"
                      onClick={onEdit}
                      disabled={!description.length}
                    >
                      {t('messages.done')}
                    </Button>
                  </div>
                ) : (
                  localCapitalize(description)
                )}
              </div>
              {isProvider && <ReviewMenu review={promotedReview} />}
            </div>
          ) : null}
          <div className={classes.partnershipContainer}>
            {partnershipInfo(provider)}
          </div>
          {!!images.length && (
            <div className={classes.lineItemRow}>
              <Carousel images={images} />
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              onClick={onClickAddReview}
            >
              {t('messages.addReview')}
            </Button>
            <Button
              color="primary"
              startIcon={<ReportProblem />}
              size="small"
              className={classes.reportProblemButton}
              href={reportProblemUri}
              target="_blank"
            >
              {t('messages.reportProblem')}
            </Button>
          </div>
          <Divider className={classes.divider} />
          {!!byPlace.length && <PlaceDetailReviews />}
          <SharePinDialog
            isOpen={isSharePinDialogOpen}
            onClose={onCloseSharePinDialog}
            placeDetails={selectedPlaceDetails}
            title={t('messages.share')}
            isNewPin={isSuccess}
            isGoogle={isGoogle}
            sharer={authState}
          />
        </>
      )}
    </MapLayout>
  );
};

export default DetailsView;
